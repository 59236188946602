import React from 'react';
import { Typography } from "antd";
import { Application, resource } from "@yulintu/freesia-bootstrap";
import {Lang} from "@yulintu/freesia-react-component-common";
export default function AppGalleryGalleryItem(props) {

    props.data.description = props.data.description || "暂无描述";

    return (
        <div className="layout-h center app-gallery-item--gallery-item">
            <div className="logo">
                {
                    props.data.logoSvg ? (
                        <Application.SvgIcon type={props.data.logoSvg} style={props.data.logoSvgStyle} />
                    ) : (
                        <img className="image" src={resource(props.data.logoImage)} style={props.data.logoImageStyle} />
                    )
                }
            </div>
            <div className="fill layout-v h100 w0 content">
                <div className="title text-nowrap text-ellipsis">
                <Lang.Right>{props.data.title || props.data.alias}</Lang.Right></div>
                <Typography.Paragraph className="fill h0 description second-text text-wrap" title={props.data.description} ellipsis={{ rows: 2, expandable: false }}>
                    
                    <Lang.Bottom>{props.data.description}</Lang.Bottom>
              
                </Typography.Paragraph>
            </div>
        </div >
    );
}